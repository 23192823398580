<template>
    <div class="myCount">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <!-- <el-radio-group
                        style="margin-left: 16px"
                        size="small"
                        v-if="btnP.userSearch"
                        v-model="searchVal.searchType"
                        @change="(currentPage = 1), (pagesize = 20), getData()"
                    >
                        <el-radio-button label="我的"></el-radio-button>
                        <el-radio-button label="部门"></el-radio-button>
                    </el-radio-group> -->
                    <span>状态</span>
                    <el-radio-group
                        size="small"
                        v-model="searchVal.statusVal"
                        @change="(currentPage = 1), (pagesize = 20), getData()"
                    >
                        <el-radio-button label="待支付"></el-radio-button>
                        <el-radio-button label="已支付"></el-radio-button>
                    </el-radio-group>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 210px"
                        placeholder="申请编号、客户名称、金额"
                        v-model="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="(currentPage = 1), (pagesize = 20), getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
            <div class="search-two">
                <Department
                    ref="department"
                    :departmentWidth="170"
                    :adminWidth="170"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.userSearch"
                    @searchData="searchData"
                ></Department>
            </div>
        </div>
        <Table ref="table" @onDetails="onDetails" @onPay="onPay"></Table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { declareList, declareUpdate } from '@/api/cost/declare.js';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../../components/Department';
import Table from '../table/table.vue';
export default {
    data() {
        return {
            searchVal: {
                departmentId: '',
                adminId: '',
                statusVal: '待支付',
            },
            btnP: {},
            dempData: [],
            userData: [],
            isPage: false,
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isStsts: 1,
            pageData: {},
        };
    },
    components: {
        Department,
        Table,
    },
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).data.pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).data.pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).searchVal;
            this.$refs.department.$data.departmentId =
                JSON.parse(sessionStorage.getItem('pageData')).data.param
                    .departmentId || undefined;
            this.$refs.department.$data.adminId =
                JSON.parse(sessionStorage.getItem('pageData')).data.param
                    .adminId || '';
            if (this.searchVal.departmentId) {
                this.$refs.department.getUserList(
                    JSON.parse(sessionStorage.getItem('pageData')).data.param
                        .departmentId
                );
            }
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        getData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.searchVal.statusVal == '待支付') {
                data.param.statusList = [4, 13];
            }
            if (this.searchVal.statusVal == '已支付') {
                data.param.status = 11;
            }
            if (!this.searchVal.statusVal) {
                data.param.statusList = [4, 11, 13];
            }
            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.searchVal.departmentId) {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.searchVal.name) {
                data.param.name = this.searchVal.name;
            }
            this.pageData = {
                data,
                searchVal: this.searchVal,
            };
            declareList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.table.getData(
                        res.data.list,
                        this.btnP,
                        this.searchVal.statusVal
                    );
                    this.total = res.data.total;
                }
            });
            //   billList(data).then((res) => {
            //       console.log(res.data);
            //       this.total = res.data.total;
            //       this.tableData = res.data.list;
            //   });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.dempData = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },
        onDetails(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            this.$router.push({
                path: '/declareinfodetails',
                query: {
                    id: row.id,
                    isDetails: 1,
                },
            });
        },
        onPay(row) {
            let data = {
                param: {
                    id: row.id,
                    status: 13,
                },
            };
            declareUpdate(data).then((res) => {
                if (res.code == 200) {
                    sessionStorage.setItem(
                        'pageData',
                        JSON.stringify(this.pageData)
                    );
                    this.$router.push({
                        path: '/declareinfopaydetails',
                        query: {
                            id: row.id,
                        },
                    });
                }
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>
<style lang="scss" scoped>
.myCount {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                align-items: center;
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
            .tab_box {
                display: flex;
                width: 100px;
                height: 32px;
                background: #edf1f3;
                box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500 !important;
                align-items: center;
                .iconfont {
                    font-size: 20px;
                    color: #8292b9;
                    box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                    border-radius: 50;
                    margin-left: 6px;
                    margin-right: 8px;
                }
            }
        }
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-two {
    padding-bottom: 12px;
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .status_btn {
        span {
            display: inline-block;
            border: 1px solid #f0f0f0;
            border-radius: 13px;
            padding: 4px 12px;
            font-size: 13px;
            color: #666666;
            margin-right: 12px;
            cursor: pointer;
            position: relative;
        }
        .isStatusColor {
            background: #e9f2ff;
            color: #2370eb;
            border-color: #e9f2ff;
        }
    }
}
</style>
